import { DataTabList } from '~/types/data-array'

const CrossPlatformAppDevTabList: DataTabList[] = [
  {
    name: 'Hiring platform',
  },
  {
    name: 'Fitness app',
  },
]

export default CrossPlatformAppDevTabList
