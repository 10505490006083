import { ExpertiseSectionMock } from '~/types/mock'

const CrossPlatformAppDevBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'We are an excellent cross platform development supporter for startups looking to bring their innovative ideas to life. With our expertise in creating robust and scalable software solutions, we help startups quickly develop and launch their products to market.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small and mid-scale businesses',
    description:
      'We are a reliable cross platform app development company for small and mid-scale businesses looking to enhance their digital presence. So, businesses can expect improved operational efficiency, increased revenue, and a competitive edge.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Enterprises',
    description:
      'We are a top cross platform development counterpart for enterprises that require high-performing software solutions. With our end-to-end services, we ensure enterprises have a reliable and robust software solution that meets their business objectives.',
  },
]

export default CrossPlatformAppDevBusinesses
