import { ExpertiseSectionMock } from '~/types/mock'

const CrossPlatformAppDevPreferences: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_1',
    title: 'Development experience',
    description:
      'We have the skills and expertise to build high-quality cross platform mobile apps that meet the unique requirements of your business.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost-effective services',
    description:
      'Our team works efficiently to deliver projects on time and within budget, helping you save money on development costs.',
  },
  {
    icon: 'spriteIcon_79',
    title: 'Agile approach',
    description:
      'We use this approach to ensure that your cross platform mobile app is delivered on time and meets your business needs.',
  },
  {
    icon: 'spriteIcon_43',
    title: 'Advanced tech stack',
    description:
      'Codica’s team stays up-to-date with the latest trends in mobile app development, ensuring that your app is built using the best tools and practices.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'Integration with third-party services',
    description:
      'We deliver this integration to ensure that your app is connected to the services that your customers use, providing a seamless user experience.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Security and scalability',
    description:
      'Our QA experts use industry-standard security practices to protect your app from threats and ensure that it can handle increased traffic as your business grows.',
  },
]

export default CrossPlatformAppDevPreferences
