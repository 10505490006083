import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/cross-platform-app-development/Anchors'
import CrossPlatformAppDevBenefits from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevBenefits'
import CrossPlatformAppDevBusinesses from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevBusinesses'
import CrossPlatformAppDevExpertise from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevExpertise'
import CrossPlatformAppDevFAQ from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevFAQ'
import CrossPlatformAppDevPreferences from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevPreferences'
import CrossPlatformAppDevProcess from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevProcess'
import CrossPlatformAppDevServices from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevServices'
import CrossPlatformAppDevTabContent from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevTabContent'
import CrossPlatformAppDevTabList from '~/mock/services/cross-platform-app-development/CrossPlatformAppDevTabList'
import { fileToImageLikeData } from '~/utils'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSectionCrossPlatform from '~/views/Services/components/ServicesTechStackSectionCrossPlatform'
import useCrossPlatformAppDevelopmentStaticQuery from '~/views/Services/CrossPlatformAppDevelopment/useCrossPlatformAppDevelopmentStaticQuery'

import * as containerStyles from './CrossPlatformAppDevelopment.module.scss'

const CrossPlatformAppDevelopment = () => {
  const query = useCrossPlatformAppDevelopmentStaticQuery()

  const crossPlatformAppDevTabContent = CrossPlatformAppDevTabContent()
  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const clientRef = getImage(fileToImageLikeData(query.clientRef))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Cross Platform App"
        title="Development Services"
        description="You can unleash the full potential of your business with Codica. Let’s build an app that works seamlessly across all devices and platforms!"
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Cross Platform App Development Services"
        breadcrumbCurrentPath="/services/cross-platform-app-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.crossPlatformServicesSection}
      >
        <HeadSection
          position="center"
          title="Our extensive cross platform mobile app development services"
          description="From ideation to launch, we deliver comprehensive cross-platform app development services that help businesses build scalable and robust apps that work seamlessly across multiple platforms. Moreover, our agile development process ensures that our clients receive regular updates and can change their apps during development."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={CrossPlatformAppDevServices} />
      </section>

      <section
        id="benefits"
        className={containerStyles.crossPlatformBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Essential benefits of cross platform mobile app development"
          description="Cross platform mobile app development is a cost-effective, powerful, and beneficial solution for creating high-quality mobile apps that work seamlessly across multiple platforms. Therefore, cross platform development has many advantages."
          descriptionLarge
        />

        <ServicesIconSmallCard
          isColSm
          dataContent={CrossPlatformAppDevBenefits}
        />
      </section>

      <CallToAction
        title="Want to bring your new idea to life with a mobile app?"
        subtitle="Try our cross-platform app development services."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="industries"
        className={containerStyles.crossPlatformIndustriesSection}
      >
        <HeadSection
          position="center"
          title="We create exciting cross platform apps for many industries"
          description={
            <>
              Our successful projects demonstrate our ability to provide
              customized solutions that meet the unique needs of our clients. We
              specialize in delivering
              <Link
                to="/services/mobile-app-development/"
                className="mx5"
                key="keyLinkDescription"
              >
                mobile app development services
              </Link>
              in various areas of our lives.
            </>
          }
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={CrossPlatformAppDevExpertise} />
      </section>

      <section
        id="business-type"
        className={containerStyles.crossPlatformBusinessSection}
      >
        <HeadSection
          position="center"
          title="Codica is a trusted development partner for different business types"
          description="The Codica team is a leading cross platform mobile app development company. The businesses trust us as we help them achieve their marketing goals. With our services, different business types can be confident that they have a reliable and knowledgeable partner who can support them in staying ahead of the competition. It is extremely important in today's rapidly evolving marketing landscape."
          titleLarge
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={CrossPlatformAppDevBusinesses} />
      </section>

      <section
        id="process"
        className={containerStyles.crossPlatformProcessSection}
      >
        <HeadSection
          position="center"
          title="Cross platform mobile app development process we deliver"
          description="We are in touch with our customers from the first stage of app development to post-launch support and maintenance. This approach helps our specialists create high-quality and user-friendly apps that meet the specific needs of our clients."
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={CrossPlatformAppDevProcess} />
      </section>

      <section
        className={containerStyles.crossPlatformTechStackSection}
        id="tech-stack"
      >
        <HeadSection
          position="center"
          title="Successful projects needs the best cross platform app development technologies"
          description="Using advanced cross platform tools and technologies, our developers can create apps that meet our clients’ needs and even exceed their expectations. This tech stack provides our team the flexibility and convenience of building apps for multiple platforms without writing separate code for each platform."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSectionCrossPlatform />
        <div
          className={
            containerStyles.crossPlatformTechStackSection__callToAction
          }
        >
          <CallToAction
            title="Want to start building your dream app?"
            subtitle="We create a seamless experience on any device."
            classNameForGA="servicesCTA_2"
            linkName="Let's talk"
            link="/contacts/"
          />
        </div>
      </section>

      <section className={containerStyles.crossPlatformExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica is about many years of cross platform app development expertise"
          description={
            <>
              Codica is not just another
              <Link to="/" className="ml5" key="keyLinkDescriptionHome">
                software development company
              </Link>
              . With many years of cross platform app development services, we
              have become a true industry leader.
            </>
          }
          titleLarge
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'Our focus on building long-term client relationships sets Codica apart from other development companies. We don’t just deliver a product and move on; we work closely with our clients to ensure that their software continues to meet their evolving needs over time.',
            },
            {
              text: 'We have delivered over 50 successful projects with a team of over 60 professionals. Our startup clients have raised over $56 million. As a result, we have established a reputation as a trustworthy software developer with over eight years of experience.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.crossPlatformPreferencesSection}>
        <HeadSection
          position="center"
          title="Preferences to develop cross platform mobile app with Codica"
          description="Codica prioritizes providing a seamless development experience when creating cross platform mobile apps. With our experienced team, you can get collaborative, transparent, and highly responsive development services that meet your business needs."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={CrossPlatformAppDevPreferences} />
      </section>

      <section className={containerStyles.crossPlatformCasesSection} id="cases">
        <HeadSection position="left" title="Our case studies" />
        <DiscoverWorksTabs
          dataTabList={CrossPlatformAppDevTabList}
          dataContent={crossPlatformAppDevTabContent}
        />
      </section>

      <section className={containerStyles.crossPlatformQuotesSection}>
        <HeadSection
          position="center"
          title="What our clients say about Codica"
          description="We enjoy the projects that we deliver for our clients. In turn, Codica’s clients leave us pleasant reviews that inspire our expert team to work further and even better."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s develop a remarkable cross-platform app together!"
          widthTitle="700px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom app specialists will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={CrossPlatformAppDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default CrossPlatformAppDevelopment
