// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "K_gQ";
export var crossPlatformBusinessSection = "K_gS";
export var crossPlatformCasesSection = "K_gZ";
export var crossPlatformExpertiseSection = "K_gX";
export var crossPlatformIndustriesSection = "K_gR";
export var crossPlatformPreferencesSection = "K_gY";
export var crossPlatformProcessSection = "K_gT";
export var crossPlatformQuotesSection = "K_g0";
export var crossPlatformServicesSection = "K_gP";
export var crossPlatformTechStackSection = "K_gV";
export var crossPlatformTechStackSection__callToAction = "K_gW";