import { ExpertiseSectionMock } from '~/types/mock'

const CrossPlatformAppDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Cross platform app development consulting',
    description:
      'Codica’s developers, designers, and analysts will work with you to identify the right tools for your project, ensuring that your app meets your business needs and user requirements.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'UI/UX design services',
    link: '/services/ui-ux-design/',
    description:
      'Our designers will apply the best standards and techniques to make your solution appealing and accessible. We have vast experience in designing solutions of different types and in various industries.',
  },
  {
    icon: 'sprite3DIcon_4',
    title: 'MVP development service',
    link: '/services/mvp-development/',
    description:
      'We will help you quickly and efficiently build a prototype of a product. Also, we will use agile development methodology to work closely with you to define your product vision.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Cross platform app development',
    description:
      'Our team has comprehensive expertise in React Native as a cross-platform mobile development tool. We’ll help you efficiently use it for your project based on your specific needs and goals.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Support and maintenance',
    description:
      'We ensure seamless work of your solution by providing regular and advanced support and maintenance. You get updates and security checks depending on your needs.',
  },
]

export default CrossPlatformAppDevServices
