import { HomeSpecification } from '~/types/mock'

const CrossPlatformAppDevExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'Connect with buyers and sellers across the globe with our robust and user-friendly online marketplace platforms, designed to simplify transactions and empower business growth.',
    link: '/case-studies/marketplace-platform-for-trading-domain-names/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'Boost your online presence and drive sales with our customizable eCommerce solutions to enhance customer experience and streamline the buying process.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'Explore the world with ease using our innovative travel platforms, providing you with access to the best deals, accommodations, and experiences around the globe.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'Drive your business forward with our cutting-edge automotive solutions, designed to optimize your operations and deliver exceptional customer service.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'Protect your assets and secure your future with our comprehensive insurance platforms, offering many coverage options and tailored solutions to meet your unique needs.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'Find the talent you need to grow your business with our powerful recruiting platforms. They include advanced search tools and seamless integration with your existing HR systems.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Manage your rental properties and assets easily using our intuitive and comprehensive rental platforms, designed to simplify operations and increase profitability.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'Connect with your audience and expand your reach with our advanced media platforms. They will provide you with the tools you need to distribute your content across all channels.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'Improve patient outcomes and streamline your operations with our innovative healthcare solutions, designed to enhance care delivery and improve patient engagement.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default CrossPlatformAppDevExpertise
