import { ExpertiseSectionMock } from '~/types/mock'

const CrossPlatformAppDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_17',
    title: 'Rapid development',
    description:
      'Since only one code base needs to be created, cross platform app development can save time, speed up the development process, and get the app to market faster.',
  },
  {
    icon: 'spriteIcon_10',
    title: 'Lower price',
    description:
      'It is possible to write code once and deploy it on multiple platforms. This can significantly reduce development costs compared to creating separate app versions for each platform.',
  },
  {
    icon: 'spriteIcon_73',
    title: 'Easier maintenance',
    description:
      'With a single code base, updates and bug fixes can be implemented quickly and efficiently, reducing the time and effort required for maintenance.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Wider audience',
    description:
      'By delivering cross platform app development, you can reach a larger audience because customers can use your app on different operating systems and devices.',
  },
]

export default CrossPlatformAppDevBenefits
