import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const CrossPlatformAppDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: (
      <>
        <Link to="/services/product-discovery/" key="keyTitleProductDiscovery">
          Product discovery
        </Link>
      </>
    ),
    descr:
      'The Codica team works closely with clients to understand their goals, requirements, and target audience. At this stage, we create a detailed project plan, a roadmap for app development.',
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr: (
      <>
        Our designers create wireframes and mockups to show the client how the
        app will look and function. With our great
        <Link to="/services/ui-ux-design/" className="mx5" key="keyTitleDesign">
          UI/UX design services
        </Link>
        we ensure the app is intuitive, easy to use, and attractive.
      </>
    ),
  },
  {
    num: '03',
    title: 'Mobile app development',
    descr:
      'Codica’s expert developers adhere to industry best practices, frameworks, and coding standards to ensure the application’s security, scalability, and maintainability.',
  },
  {
    num: '04',
    title: 'QA testing',
    descr:
      'Our quality assurance engineers conduct thorough testing to identify and fix bugs and ensure the app is stable, works well, and meets the client’s requirements.',
  },
  {
    num: '05',
    title: 'Deployment and support',
    descr:
      'Codica’s specialists deploy the applications in app stores. After the app is published, we provide maintenance and support to ensure everything works as it should.',
  },
]

export default CrossPlatformAppDevProcess
